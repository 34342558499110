/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.experience-component {
  overflow: visible; }
  .experience-component img {
    max-width: 100%; }
  .experience-component .photo-tile-figure img {
    height: auto;
    width: -moz-fit-content;
    width: fit-content; }
  .experience-component .nav-link img {
    height: auto;
    width: 100%; }
  .experience-component .accordion-image-header-wrapper img {
    height: auto; }
  .experience-component.experience-commerce_assets-button {
    display: inline-block; }
    .experience-component.experience-commerce_assets-button + .experience-commerce_assets-button {
      margin-left: 1rem; }
  .experience-component .experience-commerce_assets-instagram .instagram-component {
    background: #00396a;
    padding: 4% 0% 1%;
    margin-bottom: 40px !important; }
    @media (max-width: 768.98px) {
      .experience-component .experience-commerce_assets-instagram .instagram-component {
        padding-top: 10%; } }
    .experience-component .experience-commerce_assets-instagram .instagram-component .ig-gallery-container--hasText {
      max-width: inherit; }
    .experience-component .experience-commerce_assets-instagram .instagram-component .ig-gallery-container h3.ig-gallery-title {
      position: relative;
      top: -50px; }
      @media (max-width: 768.98px) {
        .experience-component .experience-commerce_assets-instagram .instagram-component .ig-gallery-container h3.ig-gallery-title {
          display: none; } }
      .experience-component .experience-commerce_assets-instagram .instagram-component .ig-gallery-container h3.ig-gallery-title.mobile {
        display: none; }
        @media (max-width: 768.98px) {
          .experience-component .experience-commerce_assets-instagram .instagram-component .ig-gallery-container h3.ig-gallery-title.mobile {
            top: 0.7rem;
            display: block;
            width: 40%;
            border-radius: 30px;
            font-size: 0.8rem;
            text-align: center !important;
            background: white;
            color: #001f61;
            margin: 0 auto;
            padding: 6px; } }
        .experience-component .experience-commerce_assets-instagram .instagram-component .ig-gallery-container h3.ig-gallery-title.mobile .ig-link {
          padding: 0;
          margin: 0px;
          word-break: keep-all;
          text-align: center !important;
          color: #001f61;
          overflow-wrap: normal; }
      .experience-component .experience-commerce_assets-instagram .instagram-component .ig-gallery-container h3.ig-gallery-title .ig-link {
        color: #ffffff;
        margin-bottom: 20px !important;
        padding-right: 35px;
        text-decoration: none; }
    .experience-component .experience-commerce_assets-instagram .instagram-component .ig-gallery-container .ig-gallery .ig-tile--IMAGE {
      margin: 0;
      background: #00396a; }
    .experience-component .experience-commerce_assets-instagram .instagram-component .ig-gallery-container .ig-gallery .slick-prev {
      right: 85px; }
      @media (max-width: 768.98px) {
        .experience-component .experience-commerce_assets-instagram .instagram-component .ig-gallery-container .ig-gallery .slick-prev {
          right: 75px; } }
    .experience-component .experience-commerce_assets-instagram .instagram-component .ig-gallery-container .ig-gallery .slick-next {
      right: 35px; }
      @media (max-width: 768.98px) {
        .experience-component .experience-commerce_assets-instagram .instagram-component .ig-gallery-container .ig-gallery .slick-next {
          right: 18px; } }
    .experience-component .experience-commerce_assets-instagram .instagram-component .ig-gallery-container .ig-gallery .slick-next, .experience-component .experience-commerce_assets-instagram .instagram-component .ig-gallery-container .ig-gallery .slick-prev {
      top: -25px; }
      @media (max-width: 768.98px) {
        .experience-component .experience-commerce_assets-instagram .instagram-component .ig-gallery-container .ig-gallery .slick-next, .experience-component .experience-commerce_assets-instagram .instagram-component .ig-gallery-container .ig-gallery .slick-prev {
          top: -2rem; } }
      @media (min-width: 769px) {
        .experience-component .experience-commerce_assets-instagram .instagram-component .ig-gallery-container .ig-gallery .slick-next, .experience-component .experience-commerce_assets-instagram .instagram-component .ig-gallery-container .ig-gallery .slick-prev {
          top: -30px;
          height: 40px;
          width: 40px; } }
      @media (min-width: 1024px) {
        .experience-component .experience-commerce_assets-instagram .instagram-component .ig-gallery-container .ig-gallery .slick-next, .experience-component .experience-commerce_assets-instagram .instagram-component .ig-gallery-container .ig-gallery .slick-prev {
          height: 45px;
          width: 45px; } }
    .experience-component .experience-commerce_assets-instagram .instagram-component .ig-gallery-container .ig-gallery .slick-slide {
      padding: 10px 0px; }
    .experience-component .experience-commerce_assets-instagram .instagram-component .ig-gallery-container .ig-gallery .slick-dots li.slick-active button::before {
      color: white; }
    .experience-component .experience-commerce_assets-instagram .instagram-component .ig-gallery-container .ig-gallery .ig-tile-link img {
      border-radius: 0; }
  .experience-component .bg-accent {
    background-color: var(--skin-bg-accent); }
    .experience-component .bg-accent.custom {
      position: relative;
      height: auto;
      border-top-left-radius: 50% 100%;
      border-top-right-radius: 50% 100%; }
  .experience-component .bg-accent-gradient {
    background: linear-gradient(105.49deg, #004987 47.63%, #38a6c4 93.7%); }
  @media (max-width: 1023.98px) {
    .experience-component.experience-commerce_layouts-infiniteRow .infinite-row .region {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      -ms-flex-pack: justify;
          justify-content: space-between; }
      .experience-component.experience-commerce_layouts-infiniteRow .infinite-row .region > div {
        -ms-flex: 0 49%;
            flex: 0 49%; } }
  .experience-component p {
    font-size: 1.125rem;
    line-height: 140%; }
    @media (min-width: 1024px) {
      .experience-component p {
        font-size: 1.25rem; } }

.image-component {
  margin: 0;
  width: 100%;
  position: relative; }

.common-image-component {
  width: auto;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: var(--focal-point-x) var(--focal-point-y);
     object-position: var(--focal-point-x) var(--focal-point-y); }
  .common-image-component.stretch {
    width: 100%; }

.common-image-filter {
  filter: brightness(40%); }

.image-heading-container {
  position: absolute;
  top: 50%;
  width: 100%; }
  @media (min-width: 544px) {
    .image-heading-container {
      padding-left: 7%; } }

.button-component .btn {
  margin: 1rem 0; }

#error-page.storepage .experience-component.experience-commerce_assets-htmlArea .body {
  width: 100%; }
  #error-page.storepage .experience-component.experience-commerce_assets-htmlArea .body .search .site-search form {
    height: 60px; }
    #error-page.storepage .experience-component.experience-commerce_assets-htmlArea .body .search .site-search form .form-control.search-field,
    #error-page.storepage .experience-component.experience-commerce_assets-htmlArea .body .search .site-search form button {
      height: inherit; }
    #error-page.storepage .experience-component.experience-commerce_assets-htmlArea .body .search .site-search form button {
      top: 5%; }
    #error-page.storepage .experience-component.experience-commerce_assets-htmlArea .body .search .site-search form .form-control.search-field {
      font-size: 16px; }

#error-page.storepage .experience-component.experience-commerce_assets-button {
  width: 100%;
  text-align: center;
  position: relative; }

.mobile-3r-1c .row {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  @media (min-width: 544px) {
    .mobile-3r-1c .row {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; } }

.skincare-viewall-articles,
.subcat-tile {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: start;
      justify-content: start;
  -ms-flex-align: center;
      align-items: center; }
  .skincare-viewall-articles-link,
  .subcat-tile-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    font-family: "BrandonGrotesque-WebBold";
    font-size: 0.8125rem;
    line-height: 1.0625rem;
    text-transform: uppercase;
    color: var(--color-primary);
    letter-spacing: 1px; }
  .skincare-viewall-articles-icon,
  .subcat-tile-icon {
    display: block;
    height: 0.75rem;
    width: 0.75rem;
    background-image: url("data:image/svg+xml,%3Csvg width='11' height='12' viewBox='0 0 11 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.47021 1.1369C6.21052 0.877208 5.78948 0.877207 5.52979 1.1369C5.27027 1.39642 5.27008 1.81714 5.52937 2.0769L8.78 5.33335H1.33333C0.965145 5.33335 0.666668 5.63183 0.666668 6.00002C0.666668 6.36821 0.965145 6.66669 1.33333 6.66669H8.78L5.52937 9.92314C5.27008 10.1829 5.27027 10.6036 5.52979 10.8631C5.78948 11.1228 6.21052 11.1228 6.47021 10.8631L10.6262 6.70713C11.0168 6.3166 11.0168 5.68344 10.6262 5.29291L6.47021 1.1369Z' fill='%23004987'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 0.8125rem;
    margin-left: 0.6875rem; }

@media (min-width: 1024px) {
  .Galderma-CA #skincare-assist .experience-main .mobile-1r-1c .mobile-2r-1c .experience-additions h1 {
    font-size: 2.25rem !important; } }

@media (max-width: 1023.98px) {
  .Galderma-CA #skincare-assist .experience-main .mobile-1r-1c .mobile-2r-1c .experience-additions h1 {
    font-size: 1.5rem; } }

@media (max-width: 1199.98px) and (min-width: 769px) {
  .Galderma-CA #skincare-assist .experience-main .mobile-1r-1c .mobile-2r-1c .experience-additions h1 {
    font-size: 1.125rem; } }

@media (min-width: 1024px) {
  .Galderma-CA #skincare-assist .experience-main .mobile-1r-1c .mobile-2r-1c .experience-additions p {
    font-size: 1.125rem; } }

@media (max-width: 1023.98px) {
  .Galderma-CA #skincare-assist .experience-main .mobile-1r-1c .mobile-2r-1c .experience-additions p {
    font-size: 1.125rem; } }

@media (max-width: 1199.98px) and (min-width: 769px) {
  .Galderma-CA #skincare-assist .experience-main .mobile-1r-1c .mobile-2r-1c .experience-additions p {
    font-size: 1rem; } }

.cetaphil_redesign .instagram-component {
  background: #ffffff !important;
  margin-bottom: 0px !important; }
  .cetaphil_redesign .instagram-component .ig-gallery-container--hasText {
    padding: 0;
    position: relative; }
  .cetaphil_redesign .instagram-component .ig-gallery-container .ig-gallery-title {
    position: absolute !important;
    top: -45px; }
    .cetaphil_redesign .instagram-component .ig-gallery-container .ig-gallery-title .ig-link {
      color: var(--color-drkblue) !important;
      font-family: "Lateral-CondensedBold", sans-serif !important;
      padding-left: 3.1rem;
      font-size: 41px; }
      @media (min-width: 769px) {
        .cetaphil_redesign .instagram-component .ig-gallery-container .ig-gallery-title .ig-link {
          font-size: 30px; } }
  .cetaphil_redesign .instagram-component .ig-gallery {
    padding-top: 20px; }
    .cetaphil_redesign .instagram-component .ig-gallery .slick-dots li button:before {
      color: var(--color-drkblue);
      width: 13px;
      height: 13px;
      line-height: 13px;
      color: transparent;
      font-size: 13px;
      font-size: 9px;
      box-shadow: inset 0 0 1px 1px #001E62;
      opacity: 0.8 !important;
      display: inline-block;
      overflow: hidden;
      background: transparent;
      border-radius: 100%; }
    .cetaphil_redesign .instagram-component .ig-gallery .slick-dots li.slick-active button::before,
    .cetaphil_redesign .instagram-component .ig-gallery .slick-dots li button:hover::before {
      color: var(--color-drkblue) !important;
      box-shadow: inset 1px 0px 1px 4px #001E62; }
    .cetaphil_redesign .instagram-component .ig-gallery .slick-dots li.slick-active button::before {
      box-shadow: inset 1px 0px 1px 4px #001E62; }
  .cetaphil_redesign .instagram-component h3.ig-gallery-title.mobile {
    position: relative !important;
    color: #001E62;
    margin: 0 auto;
    padding: 10px;
    border: 1px solid #001E62; }

@media (min-width: 769px) {
  .cetaphil_redesign .photo-tile-figure {
    padding: 15% 5% 15% 10%; } }

@media (max-width: 1023.98px) {
  .cetaphil_redesign .photo-tile-figure {
    padding: 15% 5%; } }

@media (max-width: 768.98px) {
  .cetaphil_redesign .photo-tile-figure {
    padding: 0; } }

@media (max-width: 1023.98px) {
  .cetaphil_redesign .photo-tile-figure img {
    width: 100%; } }

.cetaphil_redesign h3.ig-gallery-title.mobile {
  display: none; }
  @media (max-width: 768.98px) {
    .cetaphil_redesign h3.ig-gallery-title.mobile {
      position: relative !important;
      font-size: 0.8rem;
      background: var(--color-backup-brightblue);
      color: #fff;
      width: 32%; } }

@media (max-width: 1023.98px) {
  .cetaphil_redesign h3.ig-gallery-title {
    /*background:  var(--color-backup-darkblue);
            background-color: var(--color-backup-darkblue);
            color:  $white ;*/
    top: -32px; } }

@media (max-width: 1023.98px) {
  .cetaphil_redesign h3.ig-gallery-title .ig-link {
    font-size: 1.8rem; } }

@media (max-width: 768.98px) {
  .cetaphil_redesign h3.ig-gallery-title .ig-link {
    padding: 5px !important;
    color: #fff !important;
    font-size: 1rem !important; } }

.cetaphil_redesign .ig-tile-link {
  color: var(--color-drkblue) !important; }

.cetaphil_redesign .slider .slick-next:before,
.cetaphil_redesign .slider .slick-prev:before,
.cetaphil_redesign .slider-core-css .slick-next:before,
.cetaphil_redesign .slider-core-css .slick-prev:before,
.cetaphil_redesign .glide .glide__arrows .glide__arrow--right:before,
.cetaphil_redesign .glide .glide__arrows .glide__arrow--left:before {
  color: var(--color-drkblue) !important; }

.cetaphil_redesign .slider .slick-next,
.cetaphil_redesign .slider .slick-prev,
.cetaphil_redesign .slider-core-css .slick-next,
.cetaphil_redesign .slider-core-css .slick-prev {
  background-color: #ecf0f3;
  border: 1px solid var(--color-drkblue);
  background-image: none;
  box-shadow: none; }
  .cetaphil_redesign .slider .slick-next:hover,
  .cetaphil_redesign .slider .slick-prev:hover,
  .cetaphil_redesign .slider-core-css .slick-next:hover,
  .cetaphil_redesign .slider-core-css .slick-prev:hover {
    box-shadow: none; }

.cetaphil_redesign .slider .slick-next.slick-disabled,
.cetaphil_redesign .slider .slick-prev.slick-disabled,
.cetaphil_redesign .slider-core-css .slick-next.slick-disabled,
.cetaphil_redesign .slider-core-css .slick-prev.slick-disabled {
  border-width: 0; }

.cetaphil_redesign .glide.pd-generic-carousel .glide__arrows .glide__arrow--right,
.cetaphil_redesign .glide.pd-generic-carousel .glide__arrows .glide__arrow--left,
.cetaphil_redesign .primary-images .main-carousel-container .glide__arrow--left,
.cetaphil_redesign .primary-images .main-carousel-container .glide__arrow--right,
.cetaphil_redesign .glide .glide__arrows .glide__arrow--right,
.cetaphil_redesign .glide .glide__arrows .glide__arrow--left {
  background-color: #ecf0f3;
  border: 1px solid var(--color-drkblue);
  background-image: none;
  box-shadow: none;
  padding: 0; }

.cetaphil_redesign .glide .glide__arrows .glide__arrow--left.disabled,
.cetaphil_redesign .primary-images .main-carousel-container .glide__arrow--left.disabled,
.cetaphil_redesign .primary-images .main-carousel-container .glide__arrow--right.disabled {
  border: none;
  box-shadow: none; }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.experience-commerce_layouts-carousel .section-heading {
  margin: 1.375rem 0; }

.carousel.bg-accent {
  padding: 1rem 0; }

.carousel-wrapper {
  margin-bottom: 2.5rem; }

.pd-slider .product-tile-pd {
  margin: 0; }

.product-tile-pd .product-tile-body .color-swatches .swatches-ul {
  margin: 0; }

.pd-text-slider .pd-slide {
  display: none; }

.pd-text-slider .slick-slide {
  height: auto; }
  @media (min-width: 769px) {
    .pd-text-slider .slick-slide {
      height: 100%; } }

.pd-text-slider.slick-initialized .pd-slide {
  display: block; }

.pd-text-slider .slick-prev {
  left: 0; }
  @media (min-width: 769px) {
    .pd-text-slider .slick-prev {
      left: 0.625rem; } }

.pd-text-slider .slick-next {
  right: 0; }
  @media (min-width: 769px) {
    .pd-text-slider .slick-next {
      right: 0.625rem; } }

.pd-text-slider.slick-dotted.slick-slider {
  margin-bottom: 1.5rem; }
  @media (min-width: 769px) {
    .pd-text-slider.slick-dotted.slick-slider {
      margin-bottom: 0; } }

.slick-dots li {
  margin: 0 0.1875rem; }
  .slick-dots li button::before {
    font-size: 0.75rem; }
  .slick-dots li.slick-active button::before {
    color: #004987; }

.in-editor .carousel.edit-mode .pd-slider {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  .in-editor .carousel.edit-mode .pd-slider .pd-slide {
    display: block; }

.in-editor .carousel.edit-mode .pd-product-slider .pd-slide {
  display: block;
  max-width: 18.75rem;
  float: left; }

.in-editor .carousel.edit-mode .pd-product-slider .product-tile {
  display: block; }

.in-editor .carousel.edit-mode .pd-product-slider .tile-image {
  display: block; }

.in-editor .carousel.edit-mode .carousel-wrapper {
  border: 0.625rem solid #ffdf5e;
  position: relative; }
  .in-editor .carousel.edit-mode .carousel-wrapper::before {
    content: "Carousel Edit Mode";
    padding: 0.3125rem 0.625rem 0.625rem;
    position: absolute;
    top: -0.4375rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 200;
    background-color: #ffdf5e; }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.btn-custom {
  background-color: #004987 !important;
  border-color: #004987 !important;
  color: #fff !important; }
  .btn-custom:hover {
    background-color: #fff !important;
    border-color: #004987 !important;
    color: #004987 !important; }

.ig-gallery {
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  text-align: center; }
  .ig-gallery[data-type="flex"], .ig-gallery[data-type="Flex"] {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: justify;
        justify-content: space-between;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-direction: row;
        flex-direction: row; }
  .ig-gallery[data-type="masonry"], .ig-gallery[data-type="Masonry"] {
    display: block;
    width: 100%;
    column-gap: 0.625rem;
    column-count: 1; }
    @media (min-width: 769px) {
      .ig-gallery[data-type="masonry"], .ig-gallery[data-type="Masonry"] {
        column-count: 3; } }
    @media (min-width: 1024px) {
      .ig-gallery[data-type="masonry"], .ig-gallery[data-type="Masonry"] {
        column-count: 4; } }
  .ig-gallery[data-type="carousel"], .ig-gallery[data-type="Carousel"] {
    opacity: 0;
    transition: opacity 0.3s ease-in; }
    .ig-gallery[data-type="carousel"].slick-initialized, .ig-gallery[data-type="Carousel"].slick-initialized {
      opacity: 1; }
    @media (max-width: 543.98px) {
      .ig-gallery[data-type="carousel"] .slick-next, .ig-gallery[data-type="Carousel"] .slick-next {
        right: 1rem; }
      .ig-gallery[data-type="carousel"] .slick-prev, .ig-gallery[data-type="Carousel"] .slick-prev {
        left: 1rem; } }
  .ig-gallery-title, .ig-gallery-description {
    display: block;
    text-align: center;
    margin: 0;
    padding: 0; }
  .ig-gallery-description {
    padding-bottom: 1rem; }
  .ig-gallery-container--hasText {
    padding: 1.875rem 0; }

.ig-tile {
  position: relative;
  display: inline-block;
  margin: 0.625rem;
  padding: 0;
  max-width: 100%;
  overflow: hidden; }
  .ig-tile,
  [data-type="flex"] .ig-tile,
  [data-type="Flex"] .ig-tile {
    max-width: none;
    -ms-flex: 1 0 calc(100% - 0.625rem);
        flex: 1 0 calc(100% - 0.625rem); }
    @media (min-width: 544px) {
      .ig-tile,
      [data-type="flex"] .ig-tile,
      [data-type="Flex"] .ig-tile {
        -ms-flex: 1 0 calc(48% - 0.625rem);
            flex: 1 0 calc(48% - 0.625rem); } }
    @media (min-width: 769px) {
      .ig-tile,
      [data-type="flex"] .ig-tile,
      [data-type="Flex"] .ig-tile {
        -ms-flex: 1 0 calc(24% - 0.625rem);
            flex: 1 0 calc(24% - 0.625rem); } }
  .ig-gallery--native .ig-tile,
  [data-type="masonry"] .ig-tile,
  [data-type="Masonry"] .ig-tile {
    margin: 0.625rem 0.3125rem;
    padding: 0; }
    .ig-gallery--native .ig-tile .ig-tile-link,
    [data-type="masonry"] .ig-tile .ig-tile-link,
    [data-type="Masonry"] .ig-tile .ig-tile-link {
      padding-bottom: 0; }
    .ig-gallery--native .ig-tile .ig-tile-image,
    [data-type="masonry"] .ig-tile .ig-tile-image,
    [data-type="Masonry"] .ig-tile .ig-tile-image {
      position: static;
      transform: none; }
  .ig-gallery--carousel .ig-tile {
    margin: 0;
    padding: 0 0.625rem; }
    .ig-gallery--carousel .ig-tile .ig-tile-icon {
      right: 1.25rem; }
  .ig-tile-link {
    position: relative;
    display: block;
    width: 100%;
    padding-bottom: 100%; }
  .ig-tile-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .ig-tile-icon {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    width: 2.25rem;
    height: 2.25rem;
    position: absolute;
    top: 0.625rem;
    right: 0.625rem;
    pointer-events: none;
    color: rgba(0, 0, 0, 0.7);
    text-align: center;
    z-index: 1;
    padding: 0.1875rem;
    border-radius: 50%;
    box-shadow: var(--util-box-shadow);
    background-color: var(--skin-background-color-1); }
  .ig-tile-caption {
    color: #000;
    display: none; }
    [data-captions="true"] .ig-tile-caption {
      display: block; }
  [data-captions="true"] .ig-tile.hasCaption .ig-tile-link {
    padding-bottom: 0; }
  .ig-tile.hasCaption .ig-tile-link:hover {
    text-decoration: none; }
  [data-captions="true"] .ig-tile.hasCaption .ig-tile-image {
    position: relative;
    top: auto;
    left: auto;
    transform: none; }
  .ig-tile.hasCaption .ig-tile-caption {
    line-height: 1.25;
    margin: 0.3125rem 0 0;
    padding: 0 0.3125rem; }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.product-detail .primary-images .thumbnail-carousel-container .glide-slide {
  position: relative; }
  .product-detail .primary-images .thumbnail-carousel-container .glide-slide .preview-play-button {
    position: absolute;
    height: 20%;
    width: 20%;
    left: 50%;
    top: 50%;
    border: none;
    transform: translate(-50%, -50%);
    pointer-events: none; }
    @media (min-width: 769px) {
      .product-detail .primary-images .thumbnail-carousel-container .glide-slide .preview-play-button {
        top: 41%; } }
  .product-detail .primary-images .thumbnail-carousel-container .glide-slide .carousel-img:hover + .preview-play-button {
    opacity: 1; }

.product-detail .primary-images .thumbnail-carousel-container .glide-slide.glide__slide--active .preview-play-button {
  opacity: 1; }

img.preview-overlay {
  position: absolute;
  display: block;
  z-index: 200;
  top: 0;
  width: 100%;
  height: 100%; }
  img.preview-overlay:hover {
    cursor: pointer; }

.video-preview-image {
  position: relative; }

img.preview-play-button {
  position: absolute;
  display: block;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.video-panel-container.hide {
  display: none; }

.video-panel-container.show {
  display: block; }

.video-preview-image.hide {
  display: none; }

.video-panel-container {
  position: relative;
  width: 100%; }
  .video-panel-container .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 1.25rem; }
  .video-panel-container html {
    background-color: #f3f3f3; }
  .video-panel-container .youtube {
    aspect-ratio: 16/9;
    background-color: #000;
    position: relative;
    overflow: hidden;
    cursor: pointer; }
  .video-panel-container .youtube img {
    width: 100%;
    top: -16.82%;
    left: 0;
    opacity: 0.7; }
  .video-panel-container .youtube .play-button {
    width: 90px;
    height: 60px;
    background-color: #333;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.6);
    z-index: 1;
    opacity: 0.8;
    border-radius: 6px; }
  .video-panel-container .youtube .play-button:before {
    content: "";
    border-style: solid;
    border-width: 15px 0 15px 26.0px;
    border-color: transparent transparent transparent #fff; }
  .video-panel-container .youtube img,
  .video-panel-container .youtube .play-button {
    cursor: pointer; }
  .video-panel-container .youtube img,
  .video-panel-container .youtube iframe,
  .video-panel-container .youtube .play-button,
  .video-panel-container .youtube .play-button:before {
    position: absolute; }
  .video-panel-container .youtube .play-button,
  .video-panel-container .youtube .play-button:before {
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0); }
  .video-panel-container .youtube iframe {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0; }

.cetaphil_redesign .video-preview-image {
  width: 100%; }
  .cetaphil_redesign .video-preview-image img {
    width: 100%; }
  .cetaphil_redesign .video-preview-image .preview-play-button {
    width: auto !important; }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.ITC-container .ITC-figure {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }
  .ITC-container .ITC-figure picture {
    margin: auto; }
    .ITC-container .ITC-figure picture a {
      display: block; }
  .ITC-container .ITC-figure .first {
    -ms-flex-order: 1;
        order: 1; }
  .ITC-container .ITC-figure .second {
    -ms-flex-order: 2;
        order: 2; }

.ITC-container .ITC-text-underneath {
  text-align: center;
  padding: 24px 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-line-pack: justify;
      align-content: space-between; }

.ITC-container .heading-text {
  width: 100%; }

.ITC-container .heading-btns {
  width: 100%;
  margin-top: 0.75rem;
  display: block;
  white-space: nowrap; }
  .ITC-container .heading-btns .cta-btn {
    margin: 0.75rem 0 0;
    vertical-align: top;
    white-space: nowrap; }
  .ITC-container .heading-btns.twoButtons .cta-btn:first-child {
    margin-right: 1rem; }

@media (min-width: 544px) {
  .ITC-container.horizontal .ITC-figure {
    -ms-flex-direction: row;
        flex-direction: row; }
    .ITC-container.horizontal .ITC-figure picture {
      width: 50%; }
    .ITC-container.horizontal .ITC-figure figcaption {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center;
      width: 50%;
      -ms-flex-positive: 1;
          flex-grow: 1; } }

.ITC-container.horizontal .ITC-text {
  padding: 2rem 3rem; }
  @media (min-width: 544px) {
    .ITC-container.horizontal .ITC-text.text-left {
      padding: 2rem 7rem 2rem 3rem; }
    .ITC-container.horizontal .ITC-text.text-right {
      padding: 2rem 3rem 2rem 7rem; }
    .ITC-container.horizontal .ITC-text.text-center {
      padding: 2rem 5rem; } }

@media (min-width: 769px) {
  .ITC-container.text-slider .ITC-figure {
    display: -ms-flexbox;
    display: flex; }
    .ITC-container.text-slider .ITC-figure picture {
      width: 50%; }
    .ITC-container.text-slider .ITC-figure figcaption {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center;
      width: 50%; }
    .ITC-container.text-slider .ITC-figure .first {
      -ms-flex-order: 1;
          order: 1; }
    .ITC-container.text-slider .ITC-figure .second {
      -ms-flex-order: 2;
          order: 2; } }

.ITC-container.text-slider .pd-text-slider.slick-initialized .ITC-text {
  padding: 2rem 3rem; }
  @media (min-width: 769px) {
    .ITC-container.text-slider .pd-text-slider.slick-initialized .ITC-text.text-left {
      padding: 2rem 5rem 2rem 4rem; }
    .ITC-container.text-slider .pd-text-slider.slick-initialized .ITC-text.text-right {
      padding: 2rem 4rem 2rem 5rem; }
    .ITC-container.text-slider .pd-text-slider.slick-initialized .ITC-text.text-center {
      padding: 2rem 5rem; } }

.ITC-container.light .ITC-figure {
  background-color: var(--skin-bg-dark); }

.ITC-container.light .ITC-text-underneath h1,
.ITC-container.light .ITC-text-underneath h2,
.ITC-container.light .ITC-text-underneath h3,
.ITC-container.light .ITC-text-underneath h4,
.ITC-container.light .ITC-text-underneath p {
  color: var(--color-white); }

.ITC-container.dark .ITC-figure {
  background-color: var(--color-white); }

.ITC-container.dark .ITC-text-underneath h1,
.ITC-container.dark .ITC-text-underneath h2,
.ITC-container.dark .ITC-text-underneath h3,
.ITC-container.dark .ITC-text-underneath h4 {
  color: var(--color-primary); }

.ITC-container.dark .ITC-text-underneath p {
  color: #5e656b;
  font-family: "BrandonGrotesque-WebMedium";
  font-size: 1rem;
  line-height: 1.5rem; }

.ITC-container.no-bg .ITC-figure {
  background-color: transparent; }

.ITC-container .ITC-figure.text-overlay figcaption {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; }
  .ITC-container .ITC-figure.text-overlay figcaption .ITC-text-underneath {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    text-align: left;
    margin: 0;
    max-width: 100%;
    padding: 0; }
    @media (min-width: 544px) {
      .ITC-container .ITC-figure.text-overlay figcaption .ITC-text-underneath {
        width: auto; } }
  .ITC-container .ITC-figure.text-overlay figcaption.top .ITC-text-underneath {
    top: 2rem;
    left: 50%;
    transform: translateX(-50%);
    text-align: center; }
  .ITC-container .ITC-figure.text-overlay figcaption.right .ITC-text-underneath {
    right: 2rem;
    transform: translateY(-50%);
    text-align: right; }
  .ITC-container .ITC-figure.text-overlay figcaption.bottom .ITC-text-underneath {
    top: unset;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    text-align: center; }
  .ITC-container .ITC-figure.text-overlay figcaption.left .ITC-text-underneath {
    top: 50%;
    left: 2rem;
    transform: translateY(-50%); }
  .ITC-container .ITC-figure.text-overlay figcaption.center .ITC-text-underneath {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .ITC-container .ITC-figure.text-overlay figcaption.top.right .ITC-text-underneath {
    top: 2rem;
    right: 2rem;
    left: unset;
    transform: unset; }
  .ITC-container .ITC-figure.text-overlay figcaption.top.left .ITC-text-underneath {
    top: 2rem;
    left: 2rem;
    transform: unset;
    text-align: left; }
  .ITC-container .ITC-figure.text-overlay figcaption.bottom.right .ITC-text-underneath {
    top: unset;
    bottom: 2rem;
    right: 2rem;
    left: unset;
    transform: unset;
    text-align: right; }
  .ITC-container .ITC-figure.text-overlay figcaption.bottom.left .ITC-text-underneath {
    top: unset;
    bottom: 2rem;
    right: unset;
    left: 2rem;
    transform: unset;
    text-align: left; }
  .ITC-container .ITC-figure.text-overlay figcaption.left.center .ITC-text-underneath {
    top: 50%;
    left: 2rem;
    transform: translateY(-50%);
    text-align: left; }
    @media (min-width: 544px) {
      .ITC-container .ITC-figure.text-overlay figcaption.left.center .ITC-text-underneath {
        left: 25%;
        transform: translate(-50%, -50%); } }
  .ITC-container .ITC-figure.text-overlay figcaption.right.center .ITC-text-underneath {
    top: 50%;
    left: unset;
    right: 2rem;
    transform: translateY(-50%);
    text-align: right; }
    @media (min-width: 544px) {
      .ITC-container .ITC-figure.text-overlay figcaption.right.center .ITC-text-underneath {
        right: 25%;
        transform: translate(50%, -50%);
        text-align: left; } }

.ITC-container.ITC-accordion-container.horizontal .ITC-figure picture {
  width: auto; }
  @media (min-width: 1024px) {
    .ITC-container.ITC-accordion-container.horizontal .ITC-figure picture {
      margin-right: 30px;
      margin-bottom: 0; } }

.ITC-container.ITC-accordion-container.horizontal .ITC-text-underneath {
  padding: 0; }

.content-tile {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  overflow: hidden; }
  .content-tile-body.first {
    -ms-flex-order: 0;
        order: 0; }
  .content-tile-body.last {
    -ms-flex-order: 2;
        order: 2; }
  .content-tile-cta-container {
    margin: 1.25rem 0;
    -ms-flex: 1 0 100%;
        flex: 1 0 100%; }
  .content-tile--left, .content-tile-link--left {
    -ms-flex-pack: start;
        justify-content: flex-start;
    text-align: left; }
  .content-tile--right, .content-tile-link--right {
    -ms-flex-pack: end;
        justify-content: flex-end;
    text-align: right; }
  .content-tile--center, .content-tile-link--center {
    -ms-flex-pack: center;
        justify-content: center;
    text-align: center; }
  .content-tile-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    width: 100%; }
    .content-tile-link:hover {
      text-decoration: none; }
  .content-tile-text {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
        align-items: flex-start;
    -ms-flex-pack: start;
        justify-content: flex-start;
    -ms-flex-direction: column;
        flex-direction: column; }

.experience-component.experience-commerce_assets-accordionImageAndText .image-component .accordion-image-header-wrapper {
  display: -ms-flexbox;
  display: flex;
  margin: 15px 0; }
  .experience-component.experience-commerce_assets-accordionImageAndText .image-component .accordion-image-header-wrapper picture {
    -ms-flex-negative: 0;
        flex-shrink: 0;
    margin-right: 29px;
    width: 45px; }
    .experience-component.experience-commerce_assets-accordionImageAndText .image-component .accordion-image-header-wrapper picture img {
      width: 45px; }
    @media (min-width: 769px) {
      .experience-component.experience-commerce_assets-accordionImageAndText .image-component .accordion-image-header-wrapper picture {
        margin-top: 0;
        width: 64px; }
        .experience-component.experience-commerce_assets-accordionImageAndText .image-component .accordion-image-header-wrapper picture img {
          width: 64px; } }
  .experience-component.experience-commerce_assets-accordionImageAndText .image-component .accordion-image-header-wrapper .accordion-image-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-positive: 4;
        flex-grow: 4; }
    .experience-component.experience-commerce_assets-accordionImageAndText .image-component .accordion-image-header-wrapper .accordion-image-header * {
      margin: 0; }

@media (min-width: 769px) {
  .experience-accordionPanels .heading-text {
    max-width: 80%; } }

.experience-accordionPanels .heading-text p {
  font-size: 0.875rem;
  line-height: 1.3125rem; }
  @media (min-width: 769px) {
    .experience-accordionPanels .heading-text p {
      font-size: 1rem;
      line-height: 1.5rem; } }

.experience-accordionPanels .experience-commerce_assets-accordionImageAndText .image-component {
  -ms-flex-direction: column;
      flex-direction: column; }
  .experience-accordionPanels .experience-commerce_assets-accordionImageAndText .image-component figcaption {
    width: 100%; }
    @media (min-width: 769px) {
      .experience-accordionPanels .experience-commerce_assets-accordionImageAndText .image-component figcaption {
        padding-left: 93px; } }
  @media (min-width: 769px) {
    .experience-accordionPanels .experience-commerce_assets-accordionImageAndText .image-component .heading-btns {
      max-width: 80%; } }
  @media (min-width: 769px) {
    .experience-accordionPanels .experience-commerce_assets-accordionImageAndText .image-component.accordion-hide-heading {
      -ms-flex-direction: row;
          flex-direction: row; } }
  .experience-accordionPanels .experience-commerce_assets-accordionImageAndText .image-component.accordion-hide-heading .accordion-image-header-wrapper {
    width: -moz-fit-content;
    width: fit-content; }
  @media (min-width: 769px) {
    .experience-accordionPanels .experience-commerce_assets-accordionImageAndText .image-component.accordion-hide-heading picture {
      margin-top: 0;
      width: 64px; }
      .experience-accordionPanels .experience-commerce_assets-accordionImageAndText .image-component.accordion-hide-heading picture img {
        width: 64px; } }
  .experience-accordionPanels .experience-commerce_assets-accordionImageAndText .image-component.accordion-hide-heading picture img {
    -ms-flex-negative: 0;
        flex-shrink: 0; }
  @media (min-width: 769px) {
    .experience-accordionPanels .experience-commerce_assets-accordionImageAndText .image-component.accordion-hide-heading figcaption {
      margin: 15px 0;
      padding-left: 0;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
          align-items: center;
      -ms-flex-positive: 4;
          flex-grow: 4; } }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.body_Redesign .accordion-container {
  margin-bottom: 3.125rem; }
  @media (max-width: 1199.98px) {
    .body_Redesign .accordion-container {
      margin-bottom: 1.25rem; } }
  .body_Redesign .accordion-container .accordion-tile-figure {
    margin: 0 0 1rem; }
    @media (min-width: 1024px) {
      .body_Redesign .accordion-container .accordion-tile-figure {
        margin-right: 2.25rem; } }
    .body_Redesign .accordion-container .accordion-tile-figure .content-tile-link picture {
      width: 100%;
      height: auto;
      position: relative;
      padding-bottom: 68.25%;
      padding-top: 0; }
      .body_Redesign .accordion-container .accordion-tile-figure .content-tile-link picture img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }
  .body_Redesign .accordion-container .accordion-text-container .accordion-text-container-wrapper .accordion-header-wrapper .accordion-header {
    padding-bottom: 1rem; }
  .body_Redesign .accordion-container .accordion-text-container .accordion-text-container-wrapper .accordion-text .heading-text {
    margin-bottom: 1.5rem;
    display: inline-block;
    max-width: 100%; }
    .body_Redesign .accordion-container .accordion-text-container .accordion-text-container-wrapper .accordion-text .heading-text p {
      text-align: left; }
  .body_Redesign .accordion-container .accordion-text-container .accordion-text-container-wrapper .accordion-text .accordion-btn {
    margin: 0; }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.panel-categories h4 {
  color: #004987;
  font-size: 1.375rem;
  line-height: 1.625rem;
  text-transform: none;
  letter-spacing: 0; }

.panel-categories .editorialRichText-component-container p {
  margin-bottom: 1.75rem; }

.panel-categories img {
  border-radius: 20px;
  height: auto; }

.panel-categories .rounded-cat-tile {
  position: relative;
  display: block; }
  .panel-categories .rounded-cat-tile:hover .icon {
    background: #004987; }
  .panel-categories .rounded-cat-tile:hover .fa-arrow-right::before {
    color: #fff; }

.panel-categories .cat-link {
  position: absolute;
  top: 15px;
  left: 15px;
  font-family: "BrandonGrotesque-WebMedium"; }

.panel-categories .icon {
  width: 22px;
  height: 22px;
  position: absolute;
  bottom: 14px;
  left: 14px;
  border-radius: 20px;
  background: #fff;
  text-align: center;
  line-height: 22px; }

.cetaphil_redesign .panel-categories {
  margin: 25px; }
  @media (max-width: 1199.98px) {
    .cetaphil_redesign .panel-categories {
      margin: auto 11px;
      -ms-flex-wrap: inherit;
          flex-wrap: inherit;
      overflow: scroll;
      -ms-flex-pack: start;
          justify-content: flex-start; } }
  .cetaphil_redesign .panel-categories img {
    border-radius: 8px;
    min-height: 175px; }
    @media (max-width: 1023.98px) {
      .cetaphil_redesign .panel-categories img {
        min-height: 128px;
        min-width: 128px; } }
  .cetaphil_redesign .panel-categories ::-webkit-scrollbar {
    display: none; }
  .cetaphil_redesign .panel-categories .cat-link {
    font-family: "Lateral-StandardBold"; }

.cetaphil_redesign ::-webkit-scrollbar {
  display: none; }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.content-thumbnail {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }
  @media (max-width: 768.98px) {
    .content-thumbnail {
      margin-bottom: 1.5rem; } }
  .content-thumbnail-badges {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    letter-spacing: 0.01em;
    list-style: none;
    margin: 0 0 1rem;
    padding: 0; }
    @media (min-width: 769px) {
      .content-thumbnail-badges {
        margin: 0 0 0.75rem; } }
    .content-thumbnail-badges li {
      color: #004987;
      background-color: var(--color-backup-light-blue);
      font-family: "BrandonGrotesque-WebMedium";
      font-size: 0.6875rem;
      margin: 0 0.75rem 0 0;
      padding: 0.125rem 0.25rem;
      border-radius: 0.25rem;
      text-transform: uppercase; }
      .content-thumbnail-badges li:last-child {
        margin-right: 0; }
  .content-thumbnail-body.first {
    -ms-flex-order: 0;
        order: 0; }
  .content-thumbnail-body.last {
    -ms-flex-order: 2;
        order: 2; }
  .content-thumbnail-cta-container {
    margin: 1.25rem 0;
    -ms-flex: 1 0 100%;
        flex: 1 0 100%; }
  .content-thumbnail--center, .content-thumbnail-link--center {
    -ms-flex-pack: center;
        justify-content: center;
    text-align: center; }
  .content-thumbnail-figure .content-thumbnail-link {
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-bottom: 1.5rem; }
    .content-thumbnail-figure .content-thumbnail-link img {
      border-radius: 1.25rem;
      display: block;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
      width: 100%; }
  .content-thumbnail--left, .content-thumbnail-link--left {
    -ms-flex-pack: start;
        justify-content: flex-start;
    text-align: left; }
  .content-thumbnail--right, .content-thumbnail-link--right {
    -ms-flex-pack: end;
        justify-content: flex-end;
    text-align: right; }
  .content-thumbnail-text {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
        align-items: flex-start;
    -ms-flex-pack: start;
        justify-content: flex-start;
    -ms-flex-direction: column;
        flex-direction: column;
    width: calc(100% - 10px); }
  .content-thumbnail-description {
    margin-top: 0.25rem; }
    .content-thumbnail-description p {
      font-size: 0.875rem;
      line-height: 150%;
      color: #5e656b; }
      @media (min-width: 1024px) {
        .content-thumbnail-description p {
          font-size: 1rem; } }
      .content-thumbnail-description p a {
        color: #5e656b; }
        .content-thumbnail-description p a:active {
          color: #004987; }
  .content-thumbnail-readmore {
    display: none; }
  .content-thumbnail-icon {
    position: relative;
    height: 1.5rem;
    width: 1.5rem;
    margin-left: 0.625rem;
    border-radius: 50%;
    background-color: var(--color-backup-light-blue); }
    .content-thumbnail-icon:after {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg width='11' height='12' viewBox='0 0 11 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.47021 1.1369C6.21052 0.877208 5.78948 0.877207 5.52979 1.1369C5.27027 1.39642 5.27008 1.81714 5.52937 2.0769L8.78 5.33335H1.33333C0.965145 5.33335 0.666668 5.63183 0.666668 6.00002C0.666668 6.36821 0.965145 6.66669 1.33333 6.66669H8.78L5.52937 9.92314C5.27008 10.1829 5.27027 10.6036 5.52979 10.8631C5.78948 11.1228 6.21052 11.1228 6.47021 10.8631L10.6262 6.70713C11.0168 6.3166 11.0168 5.68344 10.6262 5.29291L6.47021 1.1369Z' fill='%23004987'/%3E%3C/svg%3E%0A");
      background-size: 0.8125rem;
      background-repeat: no-repeat;
      background-position: center;
      height: 1.5rem;
      width: 1.5rem; }
  .content-thumbnail-title {
    letter-spacing: 0.01em;
    text-transform: none;
    -ms-flex: 2;
        flex: 2; }
  .content-thumbnail-figure {
    margin: 0; }
  .content-thumbnail-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
        align-items: flex-start;
    width: 100%; }
    .content-thumbnail-link:hover {
      text-decoration: none;
      color: var(--color-secondary); }
      .content-thumbnail-link:hover .content-thumbnail-icon {
        background-color: var(--color-primary); }
        .content-thumbnail-link:hover .content-thumbnail-icon:after {
          background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMSAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuNDcwMjEgMS4xMzY5QzYuMjEwNTIgMC44NzcyMDggNS43ODk0OCAwLjg3NzIwNyA1LjUyOTc5IDEuMTM2OUM1LjI3MDI3IDEuMzk2NDIgNS4yNzAwOCAxLjgxNzE0IDUuNTI5MzcgMi4wNzY5TDguNzggNS4zMzMzNUgxLjMzMzMzQzAuOTY1MTQ1IDUuMzMzMzUgMC42NjY2NjggNS42MzE4MyAwLjY2NjY2OCA2LjAwMDAyQzAuNjY2NjY4IDYuMzY4MjEgMC45NjUxNDUgNi42NjY2OSAxLjMzMzMzIDYuNjY2NjlIOC43OEw1LjUyOTM3IDkuOTIzMTRDNS4yNzAwOCAxMC4xODI5IDUuMjcwMjcgMTAuNjAzNiA1LjUyOTc5IDEwLjg2MzFDNS43ODk0OCAxMS4xMjI4IDYuMjEwNTIgMTEuMTIyOCA2LjQ3MDIxIDEwLjg2MzFMMTAuNjI2MiA2LjcwNzEzQzExLjAxNjggNi4zMTY2IDExLjAxNjggNS42ODM0NCAxMC42MjYyIDUuMjkyOTFMNi40NzAyMSAxLjEzNjlaIiBmaWxsPSIjZmZmZmZmIi8+Cjwvc3ZnPgo="); }

.product.pd-slide .content-thumbnail-container {
  margin-right: 0.8125rem; }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.not-fw .image-banner > .row {
  margin-right: 0;
  margin-left: 0; }

.image-banner .image-heading-container {
  padding: 0;
  position: static; }
  .image-banner .image-heading-container .heading-btns {
    display: block;
    margin-top: 0.75rem; }
    .image-banner .image-heading-container .heading-btns .cta-btn {
      margin: 0.75rem 0;
      vertical-align: top;
      white-space: nowrap; }
    .image-banner .image-heading-container .heading-btns.twoButtons .cta-btn:first-child {
      margin-right: 1rem; }
  .image-banner .image-heading-container .heading-inner {
    text-align: left; }
    .image-banner .image-heading-container .heading-inner.left {
      text-align: left; }
    .image-banner .image-heading-container .heading-inner.right {
      text-align: right; }
    .image-banner .image-heading-container .heading-inner.center {
      text-align: center; }
    .image-banner .image-heading-container .heading-inner p {
      font-size: 1.25rem;
      line-height: 1.75rem; }
    .image-banner .image-heading-container .heading-inner h4 {
      letter-spacing: 2px; }
  .image-banner .image-heading-container.dark .image-heading-text {
    color: #004987; }
    .image-banner .image-heading-container.dark .image-heading-text p {
      color: #004987; }
  .image-banner .image-heading-container.light .image-heading-text {
    color: #fff; }
    .image-banner .image-heading-container.light .image-heading-text p {
      color: #fff; }
  .image-banner .image-heading-container.text-below {
    padding-top: 1rem; }
    .image-banner .image-heading-container.text-below .heading-inner {
      display: inline-block; }
    .image-banner .image-heading-container.text-below.left .image-heading-text {
      text-align: left; }
    .image-banner .image-heading-container.text-below.right .image-heading-text {
      text-align: right; }
    .image-banner .image-heading-container.text-below.center .image-heading-text {
      text-align: center; }
  .image-banner .image-heading-container.text-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }
    .image-banner .image-heading-container.text-overlay > .container {
      position: relative;
      height: 100%;
      width: 100%; }
    .image-banner .image-heading-container.text-overlay .image-heading-text {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      text-align: left;
      margin: 0;
      max-width: 100%; }
      @media (min-width: 544px) {
        .image-banner .image-heading-container.text-overlay .image-heading-text {
          width: auto; } }
    .image-banner .image-heading-container.text-overlay .heading-btns .cta-btn {
      margin-bottom: 0; }
    .image-banner .image-heading-container.text-overlay.top .image-heading-text {
      top: 1rem;
      left: 50%;
      transform: translateX(-50%);
      text-align: center; }
    .image-banner .image-heading-container.text-overlay.right .image-heading-text {
      right: 0;
      transform: translateY(-50%);
      text-align: right; }
    .image-banner .image-heading-container.text-overlay.bottom .image-heading-text {
      top: unset;
      bottom: 5vh;
      left: 50%;
      transform: translateX(-50%);
      text-align: center; }
    .image-banner .image-heading-container.text-overlay.left .image-heading-text {
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      text-align: left; }
    .image-banner .image-heading-container.text-overlay.center .image-heading-text {
      top: 50%;
      left: 50%;
      text-align: center;
      transform: translate(-50%, -50%); }
    .image-banner .image-heading-container.text-overlay.top.right .image-heading-text {
      top: 5vh;
      right: 0;
      left: unset;
      transform: unset;
      text-align: right; }
    .image-banner .image-heading-container.text-overlay.top.left .image-heading-text {
      top: 5vh;
      left: 0;
      transform: unset;
      text-align: left; }
    .image-banner .image-heading-container.text-overlay.bottom.right .image-heading-text {
      top: unset;
      bottom: 5vh;
      right: 0;
      left: unset;
      transform: unset;
      text-align: right; }
    .image-banner .image-heading-container.text-overlay.bottom.left .image-heading-text {
      top: unset;
      bottom: 5vh;
      right: unset;
      left: 0;
      transform: unset;
      text-align: left; }
    .image-banner .image-heading-container.text-overlay.left.center .image-heading-text {
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      text-align: left; }
      @media (min-width: 544px) {
        .image-banner .image-heading-container.text-overlay.left.center .image-heading-text {
          left: 25%;
          transform: translate(-50%, -50%); } }
    .image-banner .image-heading-container.text-overlay.right.center .image-heading-text {
      top: 50%;
      left: unset;
      right: 0;
      transform: translateY(-50%);
      text-align: right; }
      @media (min-width: 544px) {
        .image-banner .image-heading-container.text-overlay.right.center .image-heading-text {
          right: 25%;
          transform: translate(50%, -50%);
          text-align: left; } }

.image-banner.rebranding-image-banner .image-heading-container.text-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; }
  @media (max-width: 1023.98px) {
    .image-banner.rebranding-image-banner .image-heading-container.text-overlay {
      height: 80%; } }

.image-banner.rebranding-image-banner .image-heading-container .re-image-heading-text {
  position: relative;
  width: auto;
  text-align: center;
  top: 37%;
  margin: 0 2%; }
  .image-banner.rebranding-image-banner .image-heading-container .re-image-heading-text .heading-inner {
    font-size: 1.5rem;
    color: #fff;
    font-weight: 700;
    cursor: default; }
    @media (min-width: 544px) {
      .image-banner.rebranding-image-banner .image-heading-container .re-image-heading-text .heading-inner {
        font-size: 1.875rem; } }
    @media (min-width: 769px) {
      .image-banner.rebranding-image-banner .image-heading-container .re-image-heading-text .heading-inner {
        font-size: 3.5rem; } }
    @media (min-width: 1200px) {
      .image-banner.rebranding-image-banner .image-heading-container .re-image-heading-text .heading-inner {
        font-size: 4.75rem; } }
    @media (min-width: 1400px) {
      .image-banner.rebranding-image-banner .image-heading-container .re-image-heading-text .heading-inner {
        font-size: 5.1875rem; } }

.image-banner.rebranding-image-banner .re-heading-btn {
  padding: 1rem 0;
  position: relative; }
  @media (min-width: 1024px) {
    .image-banner.rebranding-image-banner .re-heading-btn {
      position: absolute;
      top: 54%;
      left: 0;
      right: 0;
      padding: 0; } }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.product-tile-pd {
  font-family: "BrandonGrotesque-WebRegular"; }
  .product-tile-pd .product-tile-image {
    font-size: 1em; }
    .product-tile-pd .product-tile-image .quick-shop {
      background-color: #fff;
      color: #000;
      position: relative;
      display: none;
      border-radius: 0; }
    .product-tile-pd .product-tile-image .product-tile-component-figure {
      width: 100%; }
    .product-tile-pd .product-tile-image .product-tile-component-image {
      border-radius: 0;
      height: auto;
      width: 100%; }
    .product-tile-pd .product-tile-image.image-container a.productBgStyle {
      border-radius: 13px;
      height: 91%; }
  @media (min-width: 769px) {
    .product-tile-pd .product-tile-body {
      font-size: 1em;
      padding: 0.625em 0; } }
  @media (max-width: 768.98px) {
    .product-tile-pd .product-tile-body {
      font-size: 0.75em;
      padding: 0.3125em 0; } }
  .product-tile-pd .product-tile-body .product-name-link {
    color: #c3c3c3; }
  .product-tile-pd .product-tile-body .product-price {
    font-weight: 900; }
  .product-tile-pd .product-tile-body .product-ratings .rating-number {
    font-size: 0.75em; }
  @media (min-width: 769px) {
    .product-tile-pd .product-tile-body .color-swatches {
      padding-top: 0.3125em; } }
  @media (max-width: 768.98px) {
    .product-tile-pd .product-tile-body .color-swatches {
      padding-top: 0.1875em; } }
  .product-tile-pd .product-tile-body .color-swatches .product-tile-color-label {
    cursor: pointer;
    font-size: 1em; }
    @media (max-width: 1023.98px) {
      .product-tile-pd .product-tile-body .color-swatches .product-tile-color-label {
        font-size: 0.9375em; } }
    @media (max-width: 768.98px) {
      .product-tile-pd .product-tile-body .color-swatches .product-tile-color-label {
        font-size: 0.8125em; } }
  .product-tile-pd .product-tile-body .color-swatches .swatches-ul {
    list-style: none;
    padding: 0; }
    .product-tile-pd .product-tile-body .color-swatches .swatches-ul .swatch-li {
      background-repeat: no-repeat;
      background-position: 25%;
      margin-right: 0.75em;
      display: inline-block; }
      .product-tile-pd .product-tile-body .color-swatches .swatches-ul .swatch-li .swatch-button-li {
        border-radius: 50%;
        width: 1.25em;
        height: 1.25em;
        border: 0.1875em solid white;
        padding: 0;
        box-sizing: content-box; }
    .product-tile-pd .product-tile-body .color-swatches .swatches-ul .more-swatches-li,
    .product-tile-pd .product-tile-body .color-swatches .swatches-ul .more-swatches-li-sm {
      display: inline-block; }
    @media (max-width: 768.98px) {
      .product-tile-pd .product-tile-body .color-swatches .swatches-ul .swatch-li:nth-child(4),
      .product-tile-pd .product-tile-body .color-swatches .swatches-ul .swatch-li:nth-child(5) {
        display: none; }
      .product-tile-pd .product-tile-body .color-swatches .swatches-ul .more-swatches-li {
        display: none; }
      .product-tile-pd .product-tile-body .color-swatches .swatches-ul .more-swatches-li-sm {
        display: inline; } }
    .product-tile-pd .product-tile-body .color-swatches .swatches-ul .more-swatches,
    .product-tile-pd .product-tile-body .color-swatches .swatches-ul .more-swatches-sm {
      font-size: 1em;
      font-weight: 300;
      vertical-align: bottom; }
    @media (min-width: 769px) {
      .product-tile-pd .product-tile-body .color-swatches .swatches-ul .more-swatches-li {
        display: inline; }
      .product-tile-pd .product-tile-body .color-swatches .swatches-ul .more-swatches-li-sm {
        display: none; } }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
html,
body {
  margin: 0;
  padding: 0;
  line-height: normal;
  max-width: 100%;
  word-wrap: break-word;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto; }

p {
  margin: 0; }

.page-container {
  max-width: 1200px; }

.region {
  position: relative; }
  .region .product {
    height: 100%; }

.region::before {
  display: block;
  content: "";
  width: 100%; }

.infinite-row .nobasis {
  -ms-flex-preferred-size: 0;
      flex-basis: 0; }

/*
    padding-top in percentages refers to parent width
    this allows us to specify an aspect-ratio
*/
.region_landscape-large::before {
  padding-top: calc(400 / 1024 * 100%); }

.region_square::before {
  padding-top: calc(1 / 1 * 100%); }

.region_landscape-small::before {
  padding-top: calc(1 / 2 * 100%); }

.region_portrait-small::before {
  padding-top: calc(2 / 1 * 100%); }

div.sf-interactions-proxyHoverContainer a {
  overflow: visible; }

.mobile-1r-1c {
  position: relative; }
  .mobile-1r-1c div[class$="custom-bg"] {
    background-repeat: no-repeat;
    background-size: 100% auto;
    bottom: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: -1; }
  @media (min-width: 769px) {
    .mobile-1r-1c .background-1r-1c + .position-absolute-placeholder-desktop,
    .mobile-1r-1c .background-1r-1c.position-absolute-placeholder-desktop {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column;
      -ms-flex-pack: center;
          justify-content: center;
      -ms-flex-align: stretch;
          align-items: stretch;
      position: absolute;
      height: 100%;
      width: 100%; }
      .mobile-1r-1c .background-1r-1c + .position-absolute-placeholder-desktop.top,
      .mobile-1r-1c .background-1r-1c.position-absolute-placeholder-desktop.top {
        -ms-flex-pack: start;
            justify-content: flex-start; }
      .mobile-1r-1c .background-1r-1c + .position-absolute-placeholder-desktop.bottom,
      .mobile-1r-1c .background-1r-1c.position-absolute-placeholder-desktop.bottom {
        -ms-flex-pack: end;
            justify-content: flex-end; }
      .mobile-1r-1c .background-1r-1c + .position-absolute-placeholder-desktop.center,
      .mobile-1r-1c .background-1r-1c.position-absolute-placeholder-desktop.center {
        -ms-flex-pack: center;
            justify-content: center; } }
  @media (max-width: 768.98px) {
    .mobile-1r-1c .background-1r-1c + .position-absolute-placeholder-mobile,
    .mobile-1r-1c .background-1r-1c.position-absolute-placeholder-mobile {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
          flex-direction: column;
      -ms-flex-pack: center;
          justify-content: center;
      -ms-flex-align: stretch;
          align-items: stretch;
      position: absolute;
      height: 100%;
      width: 100%; }
      .mobile-1r-1c .background-1r-1c + .position-absolute-placeholder-mobile.top,
      .mobile-1r-1c .background-1r-1c.position-absolute-placeholder-mobile.top {
        -ms-flex-pack: start;
            justify-content: flex-start; }
      .mobile-1r-1c .background-1r-1c + .position-absolute-placeholder-mobile.bottom,
      .mobile-1r-1c .background-1r-1c.position-absolute-placeholder-mobile.bottom {
        -ms-flex-pack: end;
            justify-content: flex-end; }
      .mobile-1r-1c .background-1r-1c + .position-absolute-placeholder-mobile.center,
      .mobile-1r-1c .background-1r-1c.position-absolute-placeholder-mobile.center {
        -ms-flex-pack: center;
            justify-content: center; } }
  .mobile-1r-1c .region::before {
    display: none;
    height: 0; }

@media (min-width: 769px) {
  #home-banner-link .position-absolute-placeholder-desktop {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: stretch;
        align-items: stretch;
    position: absolute;
    height: 100%;
    width: 100%; }
    #home-banner-link .position-absolute-placeholder-desktop.top {
      -ms-flex-pack: start;
          justify-content: flex-start; }
    #home-banner-link .position-absolute-placeholder-desktop.bottom {
      -ms-flex-pack: end;
          justify-content: flex-end; }
    #home-banner-link .position-absolute-placeholder-desktop.center {
      -ms-flex-pack: center;
          justify-content: center; } }

@media (max-width: 768.98px) {
  #home-banner-link .position-absolute-placeholder-mobile {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: stretch;
        align-items: stretch;
    position: absolute;
    height: 100%;
    width: 100%; }
    #home-banner-link .position-absolute-placeholder-mobile.top {
      -ms-flex-pack: start;
          justify-content: flex-start; }
    #home-banner-link .position-absolute-placeholder-mobile.bottom {
      -ms-flex-pack: end;
          justify-content: flex-end; }
    #home-banner-link .position-absolute-placeholder-mobile.center {
      -ms-flex-pack: center;
          justify-content: center; } }

.background-1r-1c {
  width: 100%;
  height: auto; }
  .background-1r-1c img {
    height: auto;
    border-radius: 0;
    width: 100%;
    border-radius: 0; }

.mobile-2r-1c .row .region {
  -ms-flex-direction: column;
      flex-direction: column; }

.row.normal-order {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }
  @media (min-width: 769px) {
    .row.normal-order {
      -ms-flex-direction: row;
          flex-direction: row; } }

.row.reverse-order {
  -ms-flex-direction: column-reverse;
      flex-direction: column-reverse; }
  @media (min-width: 769px) {
    .row.reverse-order {
      -ms-flex-direction: row;
          flex-direction: row; } }

.experience-commerce_layouts-mobileGrid2r1c {
  width: 100%; }

.experience-commerce_layouts-separator {
  width: 100%; }

@media (min-width: 544px) {
  .col-sm-20 {
    -ms-flex: 0 0 20%;
        flex: 0 0 20%; } }

@media (min-width: 544px) {
  .col-sm-40 {
    -ms-flex: 0 0 40%;
        flex: 0 0 40%; } }

.col-grow {
  width: 100%;
  -ms-flex-positive: 1;
      flex-grow: 1; }
  @media (min-width: 769px) {
    .col-grow {
      width: auto; } }

.col-shrink {
  width: 100%;
  -ms-flex-positive: 0;
      flex-grow: 0; }
  @media (min-width: 769px) {
    .col-shrink {
      width: auto; } }

.panel-categories.container .row div[class^=col] .row div[class^=col] {
  margin-bottom: 20px; }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.accordion-panel-group.bg-gradient-blue {
  background: linear-gradient(105.49deg, #004987 47.63%, #38a6c4 93.7%);
  padding: 30px; }
  .accordion-panel-group.bg-gradient-blue .experience-commerce_assets-accordionPanel .panel .panel-body * {
    color: #f1f9fc !important; }
  .accordion-panel-group.bg-gradient-blue .experience-commerce_assets-accordionPanel .panel .panel-title {
    border-bottom: 1px solid #f1f9fc;
    color: #f1f9fc; }
    .accordion-panel-group.bg-gradient-blue .experience-commerce_assets-accordionPanel .panel .panel-title:hover {
      color: #38a6c4; }
      .accordion-panel-group.bg-gradient-blue .experience-commerce_assets-accordionPanel .panel .panel-title:hover::after, .accordion-panel-group.bg-gradient-blue .experience-commerce_assets-accordionPanel .panel .panel-title:hover::before {
        border-color: #f1f9fc; }
      .accordion-panel-group.bg-gradient-blue .experience-commerce_assets-accordionPanel .panel .panel-title:hover.collapsed .card-icon {
        background-color: transparent;
        border: 2px solid #38a6c4;
        color: #38a6c4; }
      .accordion-panel-group.bg-gradient-blue .experience-commerce_assets-accordionPanel .panel .panel-title:hover .card-icon {
        color: #38a6c4; }
    .accordion-panel-group.bg-gradient-blue .experience-commerce_assets-accordionPanel .panel .panel-title.collapsed .card-icon {
      background-color: transparent;
      border: 2px solid #f1f9fc;
      color: #f1f9fc; }
    .accordion-panel-group.bg-gradient-blue .experience-commerce_assets-accordionPanel .panel .panel-title .card-icon {
      color: #004987; }

.accordion-panel-group .experience-commerce_assets-accordionPanel:first-child .panel .panel-title::after, .accordion-panel-group .experience-commerce_assets-accordionPanel:first-child .panel .panel-title::before {
  top: 50%; }

.accordion-panel-group .panel.panel-default {
  color: var(--color); }

.accordion-panel-group .panel .panel-body {
  margin-top: 30px; }
  .accordion-panel-group .panel .panel-body * {
    color: #5e656b !important;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.75rem;
    position: relative; }
  .accordion-panel-group .panel .panel-body.hasBrandIcon {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
        flex-direction: row; }
    .accordion-panel-group .panel .panel-body.hasBrandIcon .panel-body--icon {
      width: 4rem;
      margin: 0 1.625rem 0 0; }
      .accordion-panel-group .panel .panel-body.hasBrandIcon .panel-body--icon img {
        max-width: 4rem; }

.accordion-panel-group .panel .panel-title {
  border-bottom: 1px solid;
  border-color: var(--color, #c3c3c3);
  color: var(--color);
  display: block;
  font-family: "BrandonGrotesque-WebRegular";
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0.01em;
  padding: 30px 0 30px 55px;
  position: relative; }
  .accordion-panel-group .panel .panel-title::after, .accordion-panel-group .panel .panel-title::before {
    content: '';
    border-bottom: 2px solid;
    position: absolute;
    top: 50%;
    width: 8px; }
  .accordion-panel-group .panel .panel-title::after {
    right: 10px;
    transform: rotate(-45deg); }
  .accordion-panel-group .panel .panel-title::before {
    right: 5px;
    transform: rotate(45deg); }
  .accordion-panel-group .panel .panel-title.collapsed {
    color: #5e656b; }
    .accordion-panel-group .panel .panel-title.collapsed::after {
      right: 10px;
      transform: rotate(45deg); }
    .accordion-panel-group .panel .panel-title.collapsed::before {
      right: 5px;
      transform: rotate(-45deg); }
    .accordion-panel-group .panel .panel-title.collapsed .card-icon {
      background-color: transparent;
      border: 2px solid #004987;
      color: var(--color, #004987); }
  .accordion-panel-group .panel .panel-title .card-icon {
    background-color: #f1f9fc;
    border: 0;
    color: var(--color, #004987);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    border-radius: 100%;
    font-size: 20px;
    font-weight: 700;
    height: 36px;
    margin-right: 20px;
    position: absolute;
    left: 0;
    text-align: center;
    width: 36px; }
  .accordion-panel-group .panel .panel-title:focus, .accordion-panel-group .panel .panel-title:focus-visible {
    outline-color: #a1d400; }
  .accordion-panel-group .panel .panel-title:hover {
    text-decoration: none;
    color: #38a6c4; }
    .accordion-panel-group .panel .panel-title:hover.collapsed::after, .accordion-panel-group .panel .panel-title:hover.collapsed::before {
      border-color: #38a6c4; }
    .accordion-panel-group .panel .panel-title:hover.collapsed .card-icon {
      background-color: transparent;
      border: 2px solid #38a6c4;
      color: #38a6c4; }
    .accordion-panel-group .panel .panel-title:hover .card-icon {
      background-color: transparent;
      border: 2px solid #38a6c4;
      color: #38a6c4; }

.bg-accent-gradient .accordion-panel-group .panel .panel-title.collapsed .card-icon {
  color: #fff;
  border-color: #fff; }

.bg-accent-gradient .accordion-panel-group .panel .panel-title.collapsed {
  color: #fff; }

.body_Redesign .accordion-panel-group .panel.panel-default .panel-collapse.show .experience-accordionPanels {
  display: inline-block;
  border-bottom: 1px solid var(--color, #c3c3c3);
  width: 100%; }

.body_Redesign .accordion-panel-group.bg-gradient-blue .experience-commerce_assets-accordionPanel .panel .panel-collapse.show .experience-accordionPanels {
  display: inline-block;
  border-bottom: 1px solid #f1f9fc;
  width: 100%; }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.carousel .headline-link {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
      align-items: center;
  text-transform: uppercase;
  text-decoration: none;
  width: -moz-max-content;
  width: max-content; }
  .carousel .headline-link .arrow-right {
    display: inline-block;
    margin-left: 0.75rem;
    width: 0.75rem;
    height: 0.75rem;
    background-image: url("data:image/svg+xml,%3Csvg width='11' height='12' viewBox='0 0 11 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.47021 1.1369C6.21052 0.877208 5.78948 0.877207 5.52979 1.1369C5.27027 1.39642 5.27008 1.81714 5.52937 2.0769L8.78 5.33335H1.33333C0.965145 5.33335 0.666668 5.63183 0.666668 6.00002C0.666668 6.36821 0.965145 6.66669 1.33333 6.66669H8.78L5.52937 9.92314C5.27008 10.1829 5.27027 10.6036 5.52979 10.8631C5.78948 11.1228 6.21052 11.1228 6.47021 10.8631L10.6262 6.70713C11.0168 6.3166 11.0168 5.68344 10.6262 5.29291L6.47021 1.1369Z' fill='%23004987'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 0.75rem; }
  .carousel .headline-link:hover {
    color: var(--color-primary);
    text-decoration: underline; }
  .carousel .headline-link:focus {
    box-shadow: none; }

.carousel .carousel-header {
  margin-bottom: 2.5rem; }
  @media (min-width: 769px) {
    .carousel .carousel-header {
      margin-bottom: 3rem; } }

.carousel .carousel-title {
  margin-bottom: 0.375rem; }
  @media (min-width: 769px) {
    .carousel .carousel-title {
      margin-bottom: 1.125rem; } }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.discoverMore-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }
  @media (min-width: 769px) {
    .discoverMore-container {
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap; } }

.discoverMore-container-item {
  width: 49%;
  margin-top: 20px;
  display: block; }
  @media (min-width: 769px) {
    .discoverMore-container-item {
      width: 33.3%; } }
  .discoverMore-container-item .image-cropper {
    padding-right: 12px; }
  .discoverMore-container-item .rounded-cat-tile {
    position: relative;
    display: block; }
    .discoverMore-container-item .rounded-cat-tile .cat-link {
      font-size: 1rem;
      position: absolute;
      top: 15px;
      left: 15px;
      font-family: "BrandonGrotesque-WebMedium"; }
    .discoverMore-container-item .rounded-cat-tile img {
      border-radius: 20px;
      max-width: 100%;
      width: 100%; }
    .discoverMore-container-item .rounded-cat-tile .icon {
      width: 22px;
      height: 22px;
      position: absolute;
      bottom: 14px;
      left: 14px;
      border-radius: 20px;
      background: #fff;
      text-align: center;
      line-height: 22px; }

.experience-commerce_assets-categoryTile .image-cropper a:hover .discoverMore-below-icon {
  background-color: var(--color-primary); }
  .experience-commerce_assets-categoryTile .image-cropper a:hover .discoverMore-below-icon::after {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMSAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTYuNDcwMjEgMS4xMzY5QzYuMjEwNTIgMC44NzcyMDggNS43ODk0OCAwLjg3NzIwNyA1LjUyOTc5IDEuMTM2OUM1LjI3MDI3IDEuMzk2NDIgNS4yNzAwOCAxLjgxNzE0IDUuNTI5MzcgMi4wNzY5TDguNzggNS4zMzMzNUgxLjMzMzMzQzAuOTY1MTQ1IDUuMzMzMzUgMC42NjY2NjggNS42MzE4MyAwLjY2NjY2OCA2LjAwMDAyQzAuNjY2NjY4IDYuMzY4MjEgMC45NjUxNDUgNi42NjY2OSAxLjMzMzMzIDYuNjY2NjlIOC43OEw1LjUyOTM3IDkuOTIzMTRDNS4yNzAwOCAxMC4xODI5IDUuMjcwMjcgMTAuNjAzNiA1LjUyOTc5IDEwLjg2MzFDNS43ODk0OCAxMS4xMjI4IDYuMjEwNTIgMTEuMTIyOCA2LjQ3MDIxIDEwLjg2MzFMMTAuNjI2MiA2LjcwNzEzQzExLjAxNjggNi4zMTY2IDExLjAxNjggNS42ODM0NCAxMC42MjYyIDUuMjkyOTFMNi40NzAyMSAxLjEzNjlaIiBmaWxsPSIjZmZmZmZmIi8+Cjwvc3ZnPgo="); }

.discoverMore-below-icon {
  position: absolute;
  top: 0;
  right: 0;
  height: 1.5rem;
  width: 1.5rem;
  margin-left: 0.625rem;
  border-radius: 50%;
  background-color: var(--color-backup-light-blue); }
  .discoverMore-below-icon::after {
    position: absolute;
    top: 0;
    left: 0;
    content: '';
    background-size: 0.8125rem;
    background-repeat: no-repeat;
    background-position: center;
    height: 1.5rem;
    width: 1.5rem;
    background-image: url("data:image/svg+xml,%3Csvg width='11' height='12' viewBox='0 0 11 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.47021 1.1369C6.21052 0.877208 5.78948 0.877207 5.52979 1.1369C5.27027 1.39642 5.27008 1.81714 5.52937 2.0769L8.78 5.33335H1.33333C0.965145 5.33335 0.666668 5.63183 0.666668 6.00002C0.666668 6.36821 0.965145 6.66669 1.33333 6.66669H8.78L5.52937 9.92314C5.27008 10.1829 5.27027 10.6036 5.52979 10.8631C5.78948 11.1228 6.21052 11.1228 6.47021 10.8631L10.6262 6.70713C11.0168 6.3166 11.0168 5.68344 10.6262 5.29291L6.47021 1.1369Z' fill='%23004987'/%3E%3C/svg%3E%0A"); }

.cetaphil_redesign .image-cropper {
  padding-right: 22px !important; }
  @media (max-width: 768.98px) {
    .cetaphil_redesign .image-cropper {
      padding-right: 16px !important; } }
  .cetaphil_redesign .image-cropper a {
    color: var(--color-drkblue) !important; }
  .cetaphil_redesign .image-cropper .position-relative.mt-3 {
    text-align: center;
    margin-top: 0.5rem !important; }

.cetaphil_redesign p.cat-link-below {
  font-size: 1.125rem !important;
  color: var(--color-drkblue) !important;
  display: inline-block;
  vertical-align: middle; }
  @media (max-width: 768.98px) {
    .cetaphil_redesign p.cat-link-below {
      font-size: 0.875rem !important; } }

.cetaphil_redesign .discoverMore-below-icon {
  float: initial;
  position: relative !important;
  vertical-align: top;
  margin-left: 0 !important; }

.cetaphil_redesign .discoverMore-below-icon::after {
  background-image: url(../../images/rightarrow_blue.svg) !important;
  height: 16px;
  width: 16px;
  fill: #001E62;
  margin-top: 3px; }
  @media (max-width: 1023.98px) {
    .cetaphil_redesign .discoverMore-below-icon::after {
      margin-top: 1px; } }
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
    .cetaphil_redesign .discoverMore-below-icon::after {
      margin-top: 3px; } }
  @media (max-width: 768.98px) {
    .cetaphil_redesign .discoverMore-below-icon::after {
      margin-top: -1px; } }

.cetaphil_redesign .discoverMore-below-icon.appledevices::after {
  margin-top: 3px; }
  @media (max-width: 768.98px) {
    .cetaphil_redesign .discoverMore-below-icon.appledevices::after {
      margin-top: 3px; } }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.img-gallery {
  position: relative; }
  .img-gallery .row {
    margin-right: -10px;
    margin-left: -10px; }
  .img-gallery .position-absolute-placeholder {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-pack: center;
        justify-content: center;
    -ms-flex-align: stretch;
        align-items: stretch;
    position: absolute;
    height: 100%;
    width: 100%; }
  .img-gallery picture {
    width: 100%; }
    .img-gallery picture img {
      width: 100%; }
  .img-gallery .gallery-title {
    font-size: 1.5rem;
    color: #004987;
    letter-spacing: 2px; }
    @media (min-width: 769px) {
      .img-gallery .gallery-title {
        font-size: 2rem;
        line-height: 42px; } }
  .img-gallery .region {
    margin-top: 35px; }
  .img-gallery p {
    font-size: 1.125rem;
    line-height: 24px; }
    @media (min-width: 769px) {
      .img-gallery p {
        font-size: 1.25rem;
        line-height: 28px; } }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.pd-nav-row {
  background: #e0eff9;
  display: -ms-flexbox;
  display: flex;
  overflow-x: scroll;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  overflow-y: hidden; }
  @media (min-width: 769px) {
    .pd-nav-row {
      overflow: hidden; } }
  .pd-nav-row .row-links.row {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap; }
    .pd-nav-row .row-links.row::before {
      display: none; }
    .pd-nav-row .row-links.row .tile-wrap {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: center;
          justify-content: center;
      padding: 0 1.8125rem; }
    .pd-nav-row .row-links.row .anchor-link {
      font-size: 0.75rem; }

.experience-page.sticky-top .anchor a {
  position: relative;
  top: -75px; }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.experience-component .product-routine img {
  height: auto;
  margin-bottom: 30px; }

.experience-component .product-routine p {
  font-size: 1rem;
  color: #636569;
  margin-bottom: 20px; }
  .experience-component .product-routine p.brand-primary {
    font-size: 1.125rem;
    color: #004987; }

.editorialRichText-component-container a {
  color: var(--skin-link-color-2); }

.cetaphil_redesign .editorialRichText-component-container a {
  font-family: "Lateral-StandardRegular"; }

.cetaphil_redesign .editorialRichText-component-container strong {
  font-family: "Lateral-CondensedBold"; }

.cetaphil_redesign .subcat-tile-row .subcat-tile-link {
  font-family: "Lateral-CondensedBold"; }

.storepage {
  width: 100%; }

.store-page-heading {
  display: none; }

.body_Redesign {
  margin-top: 136px; }

@media (max-width: 768px) {
  .body_Redesign {
    margin-top: 104px; } }
